import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06168b4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-breadcrumbs" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "neutral--text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_ctx.links.length > 1 && _ctx.backLink)
        ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
            _createVNode(_component_tm_button, {
              "icon-only": "",
              class: "mr-3",
              to: _ctx.backLink.name ? { name: _ctx.backLink.name } : _ctx.backLink.url
            }, {
              default: _withCtx(() => [
                _createVNode(_component_tm_icon, {
                  name: "chevron_left",
                  size: "xLarge",
                  class: "neutral--text"
                })
              ]),
              _: 1
            }, 8, ["to"])
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: link.name || link.url
        }, [
          (link.name || link.url)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: link.name ? { name: link.name } : link.url
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(link.label), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(link.label), 1)),
          (index + 1 < _ctx.links.length)
            ? (_openBlock(), _createBlock(_component_tm_icon, {
                key: 2,
                name: "chevron_right",
                class: "tm-breadcrumbs--divider"
              }))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}