
import { defineComponent } from 'vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import { rootScrollClassName } from '@/definitions/_general/_data/general'

export default defineComponent({
  components: {
    TmScrollbar,
  },
  setup() {
    return {
      rootScrollClassName,
    }
  },
})
