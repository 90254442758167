
import { defineComponent, computed } from 'vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import TmBreadcrumbsMobile from '@/components/shared/responsive/breadcrumbs/TmBreadcrumbsMobile.vue'
import TmBreadcrumbsDesktop from '@/components/shared/responsive/breadcrumbs/TmBreadcrumbsDesktop.vue'

export default defineComponent({
  components: {
    TmBreadcrumbsMobile,
    TmBreadcrumbsDesktop,
  },
  setup() {
    const { isSmMax } = useBreakpoints()
    const componentName = computed(() => isSmMax.value ? 'tm-breadcrumbs-mobile' : 'tm-breadcrumbs-desktop')

    return {
      componentName,
    }
  },
})
