import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-1 d-flex align-center" }
const _hoisted_2 = { class: "font-weight-medium lh-20 neutral--text ml-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_button, {
      "icon-only": "",
      to: _ctx.backLink.name ? { name: _ctx.backLink.name } : _ctx.backLink.url
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_icon, {
          name: "chevron_left",
          size: "xLarge"
        })
      ]),
      _: 1
    }, 8, ["to"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.currentLink.label), 1)
  ]))
}