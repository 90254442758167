
import type { PropType } from 'vue'
import { defineComponent, computed } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { BreadcrumbsLink } from '@/definitions/shared/types'

export default defineComponent({
  name: 'Breadcrumbs',
  components: {
    TmButton,
  },
  props: {
    links: {
      type: Array as PropType<BreadcrumbsLink[]>,
      required: true,
    },
  },
  setup(props) {
    const backLink = computed(() => props.links.at(-2))
    const currentLink = computed(() => props.links.at(-1))

    return {
      backLink,
      currentLink,
    }
  },
})
